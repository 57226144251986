$(function () {
  //////ナビゲーション
  $('.menu_trigger').on('click', function () {
    $(this).toggleClass('active');
    $('.nav_contents').slideToggle('');
  });
  $('.nav_contents a').on('click', function () {
    if (window.innerWidth <= 1150) {
      $('.menu_trigger').click();
    }
  });
  $(window).on('resize', function () {
    if ('none' == $('.nav_contents').css('display')) {
      $('.nav_contents').attr('style', '');
    }
  });
  //////ページトップ
  var topBtn = $('#page-top');
  topBtn.hide();
  $(window).scroll(function () {
    if ($(this).scrollTop() > 650) {
      topBtn.fadeIn();
    } else {
      topBtn.fadeOut();
    }
  });
  topBtn.click(function () {
    $('body,html').animate(
      {
        scrollTop: 0,
      },
      1000
    );
    return false;
  });
  //////スムーススクロール
  $('a[href^="#"]').click(function () {
    var speed = 1500;
    var href = $(this).attr('href');
    var target = $(href == '#' || href == '' ? 'html' : href);
    var position = target.offset().top;
    $('html, body').animate({ scrollTop: position }, speed, 'swing');
    return false;
  });
  //////telリンク
  var ua = navigator.userAgent.toLowerCase();
  var isMobile = /iphone/.test(ua) || /android(.+)?mobile/.test(ua);

  if (!isMobile) {
    $('a[href^="tel:"]').on('click', function (e) {
      e.preventDefault();
    });
  }
  // モーダルウィンドウ
  $('.js-modal-open').on('click', function () {
    $('.js-modal').fadeIn();
    return false;
  });
  $('.js-modal-close').on('click', function () {
    $('.js-modal').fadeOut();
    return false;
  });
  // 背景固定
  let scrollPosition;
  $('.js-modal-open').on('click', function () {
    scrollPosition = $(window).scrollTop();
    $('body').addClass('fixed').css({ top: -scrollPosition });
  });
  $('.js-modal-close').on('click', function () {
    $('body').removeClass('fixed').css({ top: 0 });
    window.scrollTo(0, scrollPosition);
  });
});
//////アニメーション
$(window).scroll(function () {
  $('.js-fade').each(function () {
    var pos = $(this).offset().top;
    var scroll = $(window).scrollTop();
    var windowHeight = $(window).height();
    if (scroll > pos - windowHeight + 100) {
      $(this).addClass('scroll');
    }
  });
});

//////WP用 NEWS一覧
$(function () {
  $('.post_nav li a').each(function () {
    var $href = $(this).attr('href');

    if (location.href.match($href)) {
      $(this).parent().addClass('active');
    } else {
      $(this).parent().removeClass('active');
    }
  });
});
